import React from "react"

import Layout from "../hoc/Layout"

export default () => {
  return (
    <Layout bodyClass="home" pages headerPages>
      <div className="errorPage">
        <h1>404 - Page not found</h1>
      </div>
    </Layout>
  )
}
